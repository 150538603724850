.box{
    background-color: #2F2F2F;
    border: none;
    border-radius: 10%;
    box-shadow: 8px 5px 15px 5px rgba(0,0,0,0);
    width: 5rem;
    height: 5rem;
    text-align: center;
    font-size: 5em;
    font-family: 'Fredoka', sans-serif;
    font-weight: bold;
    line-height: 5rem;
    margin: 0.5rem;
}

.x {
    color: rgb(255, 70, 37);
}

.o{
    color: rgb(44, 135, 255);
}

.box:hover{
    box-shadow: 0px 0px 15px #121212;
}