.reset-btn {
    border: none;
    border-radius: 0.5rem;
    background-color: #8568FE;
    box-shadow: inset;
    color: white;
    font-size: 1rem;
    font-weight: 700;
    padding: 0.5rem 01rem;
    margin: 2rem auto;
    display: block;
}