.scoreboard {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    width: 20rem;
    font-size: 1.5rem;
    background-color: #2F2F2F;
    margin: 3rem auto;
    border-radius: 0.5rem;
    font-weight: bold;
    font-family: 'Fredoka', sans-serif;
  }
  
.score {
  width: 100%;
  text-align: center;
  padding: 1rem 0rem;
}

.x-score {
  color: rgb(255, 70, 37);
  border-bottom: 5px solid rgb(255, 70, 37);
  border-radius: 0.5rem 0rem 0rem 0.5rem;
}
  
.o-score {
  color: rgb(44, 135, 255);
  border-bottom: 5px solid rgb(44, 135, 255);
  border-radius: 0rem 0.5rem 0.5rem 0rem;
}
  
.inactive {
  border-bottom: 5px solid transparent;
}